import React from 'react'
import Brick from '../bricks/Brick'
import CardContent from '../cards/CardContent'
import Card from '../cards/Card'
import useScrollRevealEffect from '../../../hooks/effects/useScrollRevealEffect'

const AuctaEnables = () => {
  const cardRef = useScrollRevealEffect({ stagger: 0.1 })

  return (
    <Brick
      title="message:aucta-enables"
      clear
      className="top-triangle-divider aucta-enables"
    >
      <div className="one-third-block">
        <Card ref={cardRef} className="card-simple">
          <CardContent
            animation="https://assets6.lottiefiles.com/private_files/lf30_olpm3ncv.json"
            subtitle="aucta-enables:train-employees"
            alt="train employees"
          />
        </Card>
        <Card ref={cardRef} className="card-simple">
          <CardContent
            animation="https://assets9.lottiefiles.com/private_files/lf30_eluamiqo.json"
            subtitle="aucta-enables:sales"
            alt="sales"
          />
        </Card>
        <Card ref={cardRef} className="card-simple">
          <CardContent
            animation="https://assets1.lottiefiles.com/private_files/lf30_jpi0lfoz.json"
            subtitle="aucta-enables:interactive-instructions"
            alt="interactive instructions"
          />
        </Card>
        <Card ref={cardRef} className="card-simple">
          <CardContent
            animation="https://assets10.lottiefiles.com/private_files/lf30_rjsitywh.json"
            subtitle="aucta-enables:support-tech"
            alt="support tech"
          />
        </Card>
        <Card ref={cardRef} className="card-simple">
          <CardContent
            animation="https://assets6.lottiefiles.com/private_files/lf30_hig2vwpx.json"
            subtitle="aucta-enables:cs-tools"
            alt="customer service tools"
          />
        </Card>
        <Card ref={cardRef} className="card-simple">
          <CardContent
            animation="https://assets8.lottiefiles.com/private_files/lf30_4ioacnyf.json"
            subtitle="aucta-enables:empower-partners"
            alt="empower partners"
          />
        </Card>
      </div>
    </Brick>
  )
}

export default AuctaEnables
