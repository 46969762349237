import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import HomePage from '../ui/pages/HomePage'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/en.json'

const IndexPage = props => (
  <>
    <HelmetComponent
      title={metaTags.indexTitle}
      description={metaTags.index}
      page=""
    />
    <AppWrapper {...props}>
      <HomePage />
    </AppWrapper>
  </>
)

export default IndexPage
