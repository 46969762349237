import React, { useRef, useLayoutEffect } from 'react'
import heroVideoURL from '../../../assets/videos/headerloop-v2.mp4'
import useTranslations from '../../../hooks/useTranslations'
import useRevealEffect from '../../../hooks/effects/useRevealEffect'
import Button from '../buttons/Button'
import bgPattern from '../../../assets/images/bg-pattern-left2.svg'

const Hero = () => {
  const t = useTranslations()
  const videoRef = useRef(null)
  useLayoutEffect(() => {
    const video = videoRef.current
    if (video) {
      video.playbackRate = 0.7
    }
  }, [videoRef])

  const blockRef = useRevealEffect()
  const titleRef = useRevealEffect({ delay: 0.2 })

  return (
    <div className="hero" ref={blockRef}>
      <img src={bgPattern} alt="" className="bg-pattern" />
      <div className="wrapper">
        <div className="hero-info" ref={titleRef}>
          <h1 className="hero-title" ref={titleRef}>
            {t('hero:title')}
          </h1>
          <p className="hero-subtitle" ref={titleRef}>
            {t('hero:subtitle')}
          </p>
          <div className="show-in-mobile">
            <Button as="a" text="action:try-demo" href="/demo" />
          </div>
          <div className="show-in-desktop">
            <Button as="a" text="action:try-online-demo" href="/demo" />
          </div>
        </div>
        <div className="hero-graphic">
          <video
            ref={videoRef}
            className="headerloop"
            autoPlay
            loop
            muted
            width="800"
            playsInline
          >
            <source src={heroVideoURL} />
          </video>
        </div>
      </div>
    </div>
  )
}

export default Hero
