import React from 'react'
import AuctaEnables from '../components/bricks/AuctaEnables'
import HowWeDoIt from '../components/bricks/HowWeDoIt'
import SelectedCustomers from '../components/bricks/SelectedCustomers'
import Hero from '../components/hero/Hero'
import CtaPanel from '../components/panels/CtaPanel'
import QuoteEos from '../components/quotes/QuoteEos'

const HomeView = () => {
  return (
    <>
      <Hero />
      <AuctaEnables />
      <QuoteEos />
      <HowWeDoIt />
      <SelectedCustomers />
      <div className="brick is-clear">
        <CtaPanel />
      </div>
    </>
  )
}

export default HomeView
