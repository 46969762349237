import React, { useRef, useState, useCallback, useEffect } from 'react'
import Brick from '../bricks/Brick'
import useScrollRevealEffect from '../../../hooks/effects/useScrollRevealEffect'
import useTranslations from '../../../hooks/useTranslations'
import Tabs from '../tabs/Tabs.jsx'
import Slider from 'react-slick'
import image1 from '../../../assets/images/how-we-do-it-1.png'
import image2 from '../../../assets/images/how-we-do-it-2.png'
import image3 from '../../../assets/images/how-we-do-it-3.png'
import image4 from '../../../assets/images/how-we-do-it-4.png'
import image1Mobile from '../../../assets/images/how-we-do-it-1-mobile.png'
import image2Mobile from '../../../assets/images/how-we-do-it-2-mobile.png'
import image3Mobile from '../../../assets/images/how-we-do-it-3-mobile.png'
import image4Mobile from '../../../assets/images/how-we-do-it-4-mobile.png'

const AUTO_ADVANCE_TAB_TIMER = 5

const items = [
  {
    image: image1Mobile,
    text: 'how-we-do-it:step1',
    id: 'hwdi-slide1',
  },
  {
    image: image2Mobile,
    text: 'how-we-do-it:step2',
    id: 'hwdi-slide2',
  },
  {
    image: image3Mobile,
    text: 'how-we-do-it:step2',
    id: 'hwdi-slide3',
  },
  {
    image: image4Mobile,
    text: 'how-we-do-it:step4',
    id: 'hwdi-slide4',
  },
]

const tabItems = [
  {
    id: 'hwdi-tab1',
    name: 'how-we-do-it:step1',
    content: <img src={image1} alt="how we do it sccreenshot" />,
  },
  {
    id: 'hwdi-tab2',
    name: 'how-we-do-it:step2',
    content: <img src={image2} alt="how we do it sccreenshot" />,
  },
  {
    id: 'hwdi-tab3',
    name: 'how-we-do-it:step3',
    content: <img src={image3} alt="how we do it sccreenshot" />,
  },
  {
    id: 'hwdi-tab4',
    name: 'how-we-do-it:step4',
    content: <img src={image4} alt="how we do it sccreenshot" />,
  },
]

const HowWeDoIt = () => {
  const t = useTranslations()
  const brick1Ref = useScrollRevealEffect()
  const brick2Ref = useScrollRevealEffect()

  const settings = {
    infinite: true,
    speed: 600,
    autoplaySpeed: 5000,
    fade: true,
    cssEase: 'ease-out',
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  }

  // logic for changing tabs automatically on desktop

  const [activeTab, setActiveTab] = useState(0)
  const advanceTab = () => {
    setActiveTab((activeTab + 1) % tabItems.length)
  }
  const timer = useRef()
  const cancelAutomaticTabChange = useCallback(() => {
    clearInterval(timer.current)
  }, [timer.current])

  useEffect(() => {
    timer.current = setInterval(advanceTab, AUTO_ADVANCE_TAB_TIMER * 1000)
    return () => clearInterval(timer.current)
  })

  // end of logic

  return (
    <Brick
      title="how-we-do-it:title"
      className="diagonal-divider"
      subtitle="how-we-do-it:subtitle"
      clear
    >
      <div className="show-in-mobile" ref={brick1Ref}>
        <Slider {...settings} className="process-carousel">
          {items.map(item => (
            <div className="process-carousel-item" id={item.id} key={item.id}>
              <img src={item.image} alt={t(item.text)} />
              <p className="process-carousel-text">{t(item.text)}</p>
            </div>
          ))}
        </Slider>
      </div>
      <div className="show-in-desktop" ref={brick2Ref}>
        <Tabs
          className="tabs-slider"
          tabItems={tabItems}
          activeTab={activeTab}
          onTabChange={cancelAutomaticTabChange}
        />
      </div>
    </Brick>
  )
}

export default HowWeDoIt
