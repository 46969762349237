import React from 'react'
import useTranslations from '../../../hooks/useTranslations'
import useScrollRevealEffect from '../../../hooks/effects/useScrollRevealEffect'
import eos from '../../../assets/images/brands/eos-color.png'
import ceramtec from '../../../assets/images/brands/ceramtec-color.png'
import asti from '../../../assets/images/brands/asti-color.png'
import alphr from '../../../assets/images/brands/alphr-color.png'
import LinkGatsby from '../links/LinkGatsby'
import { Link } from 'gatsby'

const SelectedCustomers = () => {
  const t = useTranslations()

  const blockRef = useScrollRevealEffect()
  const titleRef = useScrollRevealEffect()

  return (
    <div className="selected-customers">
      <div className="wrapper">
        <div
          className="selected-customers-col selected-customers-info"
          ref={titleRef}
        >
          <h1 className="selected-customers-title">
            {t('selected-customers:title')}
          </h1>
          <p className="selected-customers-subtitle">
            {t('selected-customers:subtitle')}
          </p>
          <div className="show-in-desktop">
            <LinkGatsby
              to="/demo"
              arrow
              text="action:try-online-demo-now"
              size="medium"
            />
          </div>
        </div>
        <div className="selected-customers-col">
          <Link className="customer-box" ref={blockRef} to="/ceramtec">
            <img src={ceramtec} alt="ceramtec" width={126} height={25} />
          </Link>
          <div className="customer-box" ref={blockRef}>
            <img src={asti} alt="asti" width={108} height={26} />
          </div>
        </div>
        <div className="selected-customers-col">
          <Link className="customer-box" ref={blockRef} to="/eos">
            <img src={eos} alt="eos" width={85} height={32} />
          </Link>
          <Link className="customer-box" ref={blockRef} to="/alphr">
            <img src={alphr} alt="alphr" width={101} height={33} />
          </Link>
        </div>
        <div className="show-in-mobile">
          <LinkGatsby
            to="/demo"
            arrow
            text="action:try-online-demo-now"
            size="medium"
          />
        </div>
      </div>
    </div>
  )
}

export default SelectedCustomers
