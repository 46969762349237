import React from 'react'
import MainLayout from '../layouts/MainLayout'
import HomeView from '../views/HomeView'

const HomePage = () => {
  return (
    <MainLayout className="home">
      <HomeView />
    </MainLayout>
  )
}

export default HomePage
